/***************************************************************************************************************************
 * @file: PROJECT-ROOT-FOLDER/src/styles/fonts.module.css
 * -----------------------------------------------------------------------------------------------------------------------------------------------
 * @description: This CSS module file defines the @font-face rules for the 'Anek Malayalam' font family, 
 *               ensuring the correct font files are used based on the weight of the text. It includes 
 *               multiple font weights from 100 to 800. The fonts are loaded in both woff2 and woff formats 
 *               for better browser compatibility.
 * ---------------------------------------------------------------------------------------------------------------------------------------------
 * @functionality: Each @font-face declaration specifies the font-family name ('Anek Malayalam'), the font style 
 *                 (normal), font weight (ranging from 100 to 800), the source of the font files (woff2 and woff 
 *                 formats), and the font-display property set to 'swap' for better performance and user experience.
 * ---------------------------------------------------------------------------------------------------------------------------------------------
 * Created by: Jason McCoy
 * Created on: 12/30/2023
 * ---------------------------------------------------------------------------------------------------------------------------------------------
 * Last Updated by: Jason McCoy
 * Last Updated on: 01/26/2024
 * ---------------------------------------------------------------------------------------------------------------------------------------------
 * Changes made: Added multiple font weights for 'Anek Malayalam' and included both woff2 and woff formats for each weight.
 * ---------------------------------------------------------------------------------------------------------------------------------------------
 * Notes: Ensure that the font files are correctly located in the specified paths. This file is essential for 
 *        the consistent and correct display of the 'Anek Malayalam' font across different parts of the application.
 ***************************************************************************************************************************/

/* woff2 : Super Modern Browsers */
/* woff2 :Modern Browsers */

@font-face {
  font-family: 'Anek Malayalam';
  font-style: normal;
  font-weight: 100;
  src: url('../assets/fonts/anek-malayalam/anek-malayalam-v4-malayalam-100.woff')
      format('woff2'),
    url('../assets/fonts/anek-malayalam/anek-malayalam-v4-malayalam-100.woff')
      format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Anek Malayalam';
  font-style: normal;
  font-weight: 200;
  src: url('../assets/fonts/anek-malayalam/anek-malayalam-v4-malayalam-200.woff2')
      format('woff2'),
    url('../assets/fonts/anek-malayalam/anek-malayalam-v4-malayalam-200.woff')
      format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Anek Malayalam';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/anek-malayalam/anek-malayalam-v4-malayalam-300.woff2')
      format('woff2'),
    url('../assets/fonts/anek-malayalam/anek-malayalam-v4-malayalam-300.woff')
      format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Anek Malayalam';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/anek-malayalam/anek-malayalam-v4-malayalam-400.woff2')
      format('woff2'),
    url('../assets/fonts/anek-malayalam/anek-malayalam-v4-malayalam-400.woff')
      format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Anek Malayalam';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/anek-malayalam/anek-malayalam-v4-malayalam-500.woff2')
      format('woff2'),
    url('../assets/fonts/anek-malayalam/anek-malayalam-v4-malayalam-500.woff')
      format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Anek Malayalam';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/anek-malayalam/anek-malayalam-v4-malayalam-600.woff2')
      format('woff2'),
    url('../assets/fonts/anek-malayalam/anek-malayalam-v4-malayalam-600.woff')
      format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Anek Malayalam';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/anek-malayalam/anek-malayalam-v4-malayalam-700.woff2')
      format('woff2'),
    url('../assets/fonts/anek-malayalam/anek-malayalam-v4-malayalam-700.woff')
      format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Anek Malayalam';
  font-style: normal;
  font-weight: 800;
  src: url('../assets/fonts/anek-malayalam/anek-malayalam-v4-malayalam-800.woff2')
      format('woff2'),
    url('../assets/fonts/anek-malayalam/anek-malayalam-v4-malayalam-800.woff')
      format('woff');
  font-display: swap;
}
